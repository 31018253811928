import { render, staticRenderFns } from "./OpenTechCIA.vue?vue&type=template&id=6552c258&scoped=true&"
import script from "./OpenTechCIA.vue?vue&type=script&lang=js&"
export * from "./OpenTechCIA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6552c258",
  null
  
)

export default component.exports